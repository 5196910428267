<template>
    <div>
        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.error_message }}
            </CAlert>
        </div>

        <div class="row">
            <div class="col-md-12">
                <CCard>
                    <CCardBody>
                        <CForm novalidate>
                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Corp. Name
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        required
                                        was-validated
                                        v-model="profile.company_name"
                                    />
                                    <template
                                        v-if="
                                            !profile.company_name &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Corporate name is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Type of user
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CSelect
                                        :options="category"
                                        placeholder="Select user type"
                                        required
                                        was-validated
                                        v-model="profile.company"
                                    />
                                    <template
                                        v-if="
                                            !profile.company &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Select type of user
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    First name
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        v-model="profile.first_name"
                                        required
                                        was-validated
                                    />
                                    <template
                                        v-if="
                                            !profile.first_name &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            First name is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                    Last name
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput v-model="profile.last_name" />
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Email
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        v-model="profile.email"
                                        required
                                        was-validated
                                    />
                                    <template
                                        v-if="
                                            !profile.email &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Email is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <!-- <div class="form-group d-flex mb-0">
                            <label
                                class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2"
                            >
                                Password
                            </label>
                            <div class="col-xl-8 col-lg-6 col-md-6">
                                <CInput
                                    v-model="profile.password"
                                    type= "password"
                                    required
                                    was-validated
                                />
                                <template v-if="!profile.password && buttonClick== true">
                                    <p class="errorMessage">Password is required</p>
                                </template>
                            </div>
                        </div> -->

                            <div class="form-group d-flex mb-0">
                                <label class="col-md-6 col-lg-6 col-xl-4 mt-2">
                                    Contact
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput v-model="profile.phone" />
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label class="asterisk col-md-6 col-lg-6 col-xl-4 mt-2">
                                    City
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput v-model="profile.city" />
                                    <template
                                        v-if="
                                            !profile.city &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            City is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    State
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        v-model="profile.state"
                                        required
                                        was-validated
                                    />
                                    <template
                                        v-if="
                                            !profile.state &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            State is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="form-group d-flex mb-0">
                                <label
                                    class="
                                        asterisk
                                        col-md-6 col-lg-6 col-xl-4
                                        mt-2
                                    "
                                >
                                    Postal code
                                </label>
                                <div class="col-xl-8 col-lg-6 col-md-6">
                                    <CInput
                                        required
                                        was-validated
                                        v-model="profile.postal"
                                    />
                                    <template
                                        v-if="
                                            !profile.postal &&
                                            buttonClick == true
                                        "
                                    >
                                        <p class="errorMessage">
                                            Postal is required
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div class="mb-4 d-flex align-items-center">
                                <CCol
                                    tag="label"
                                    class="
                                        col-xl-4
                                        col-6
                                        col-lg-5
                                        col-md-6
                                        col-sm-12
                                        mb-0
                                    "
                                >
                                    Status
                                </CCol>
                                <CCol
                                    class="
                                        d-flex
                                        col-8
                                        col-md-8
                                        col-lg-9
                                        col-xl-10
                                        col-sm-12
                                        align-items-center
                                    "
                                >
                                    <h6 class="mb-0">Inactive</h6>
                                    <CSwitch
                                        class="mt-1 ml-2 mr-2"
                                        color="primary"
                                        shape="pill"
                                        :checked="profile.is_active"
                                        @update:checked="changeStatus()"
                                    />
                                    <h6 class="mb-0">Active</h6>
                                </CCol>
                            </div>
                        </CForm>

                        <div class="form-group form-actions float-right">
                            <router-link to="/user">
                                <CButton type="submit" size="sm" color="danger">
                                    Cancel
                                </CButton>
                            </router-link>
                            <CButton
                                type="submit"
                                size="sm"
                                color="primary"
                                class="ml-3"
                                @click="errorCheck()"
                            >
                                Update
                            </CButton>
                        </div>
                    </CCardBody>
                </CCard>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const updateUserUrl = URL + `${"profile/user/edit/"}`;
const bankCategoryUrl = URL + `${"profile/bank/bashboard/category/"}`;

export default {
    name: "BankEditAccount",
    props: ["item"],

    data() {
        return {
            buttonClick: false,
            profile: {
                id: null,
                company_name: "",
                company: null,
                first_name: "",
                last_name: "",
                email: "",
                city: "",
                state: "",
                country: "",
                postal: "",
                phone: "",
                is_active: null,
            },
            category: [],
            checkError: false,
            errorMessage: "",
        };
    },
    methods: {
        changeStatus() {
            this.profile.is_active = !this.profile.is_active;
        },
        errorCheck() {
            this.buttonClick = true;

            if (
                this.profile.company_name.trim().length != 0 &&
                this.profile.company.length != 0 &&
                this.profile.first_name.trim().length != 0 &&
                this.profile.email.trim().length != 0 &&
                this.profile.city.trim().length != 0 &&
                this.profile.state.trim().length != 0 &&
                this.profile.postal.trim().length != 0
            ) {
                this.buttonClick = false;
                this.updateUser();
            }
        },
        updateUser() {
            console.log(this.profile.company);
            this.axios
                .put(updateUserUrl, this.profile, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.profile.id },
                })
                .then((response) => {
                    this.$router.push("/user");
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        getBankCategory() {
            this.axios
                .get(bankCategoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: "all" },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.category = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },
    },
    mounted() {
        if (this.item) {
            this.profile = this.item;
        }
    },

    beforeMount() {
        this.getBankCategory();
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },
};
</script>